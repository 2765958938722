import i18n from 'i18next';

import translation from '@app/translation';

export const initI18n = (initLang: string) => {
	i18n
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	// pass the i18n instance to react-i18next.
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
		.init({
			resources: translation,
			fallbackLng: 'en',
			lng: initLang,
			debug: false,
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
			},
			react: {
				useSuspense: false,
			},
		});

	return i18n;
};
