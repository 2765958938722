import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ItemsProviderWithStore from '@common/react/components/Core/ItemsProviderWithStore/ItemsProviderWithStore';

import { Location } from '@commonTuna/react/objects/BaseLocation';

import LocationName from '@app/components/UI/LocationName/LocationName';
import Select from '@app/components/UI/BseSelect';

interface LocationSelectProps {
	items: Array<Location>;
	form: any;
	field: any;
	atModal?: boolean;
}

const LocationSelect: React.FC<LocationSelectProps> = ({
	items,
	form,
	field,
	atModal,
}) => {
	const { t } = useTranslation();

	return <ItemsProviderWithStore<Location>
		storeName="locations"
		items={items}
		type="location"
		filters={{ count: 100 }}
		pagination={{ current: 1, pageSize: 100 }}
	>
		{(state) => <Select
			atModal={atModal}
			className="location-select"
			placeholder={t('forms.Location Preference')}
			value={field.value > 0 ? field.value : undefined}
			onChange={(value: any) => {
				form.setFieldValue(field.name, +value?.value);
				form.setFieldValue('doctorId', -1);
			}}
			aria-label="Locations"
			options={state.items.map((location) => ({
				value: location.id,
				label: <LocationName item={location} withoutLink />,
			}))}
		/>}
	</ItemsProviderWithStore>;
};

export default LocationSelect;
