import React from 'react';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';

const Loader = React.forwardRef<HTMLDivElement, any>((props, ref) => {
	return <div className="date-picker-alt" ref={ref}>
		<div className="react-datepicker-wrapper">
			<div className="react-datepicker__input-container">
				<input className="ant-input" value="" placeholder={props.placeholder} style={{ pointerEvents: 'none' }} />
			</div>
		</div>
		<i className="fa fa-calendar-o anticon-calendar" aria-hidden="true" />
	</div>;
});

const DatepickerEs = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "CustomDatePickerReact" */ '@common/react/components/base/DatePicker/AdaptiveDatePicker')),
	{
		fallback: <Loader placeholder="Date..." />,
	},
);

interface DatePickerAltProps {
	value: number;
	onChange: (date: number | null) => void;
	isMobile: boolean;
	placeholder?: string;
	utc?: boolean;
	datePickerProps?: any;
	yearsSelectOptions?: Array<number>;
	useDefaultFormat?: boolean;
	maxToday?: boolean;
	minToday?: boolean;
	selectProps?: any;
}

const LazyDatePickerReact: React.FC<DatePickerAltProps> = (props) => {
	const ref = React.useRef(null);
	const [visible, setVisible] = React.useState(false);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '30px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, []);

	return visible ? <DatepickerEs {...props} /> : <Loader placeholder={props.placeholder} ref={ref} />;
};

export default LazyDatePickerReact;
