import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';

import { List } from '@common/typescript/objects/List';

import { Page } from '@commonTuna/react/objects/Page';

import { request } from '@app/components/Api';
import { AppThunkAction } from '@app/store';

export interface MenuState {
	isLoading: boolean;
	loaded: boolean;
	items: Array<Page>;
}

export enum TypeKeys {
	REQUESTPAGES = 'HOMEREQUESTPAGES',
	RECEIVEPAGES = 'HOMERECEIVEPAGES'
}

export interface RequestMenuAction {
	type: TypeKeys.REQUESTPAGES;
}

export interface ReceiveMenuAction {
	type: TypeKeys.RECEIVEPAGES;
	items: Array<Page>;
}

type KnownPageAction = RequestMenuAction | ReceiveMenuAction;

function loadItems(dispatch: any, getState: any, type: string, path: string, requestType: string, receiveType: string) {
	if (!getState().menu.loaded) {
		const fetchTask = request<List<Page>>(path, {

		}, getState()).then((data) => {
			dispatch({ type: receiveType, items: data.list });
		});

		addTask(fetchTask);
		dispatch({ type: requestType });
	}
}

export const actionCreators = {
	reqPages: (): AppThunkAction<KnownPageAction> => (dispatch, getState) => {
		loadItems(dispatch, getState, 'pages', 'menuList', TypeKeys.REQUESTPAGES, TypeKeys.RECEIVEPAGES);
	},
};

export const reducer: Reducer<MenuState> = (
	state: MenuState = { isLoading: false, loaded: false, items: [] },
	incomingAction: Action,
) => {
	const action = incomingAction as KnownPageAction;
	switch (action.type) {
		case TypeKeys.REQUESTPAGES:
			return { ...state, isLoading: true };
		case TypeKeys.RECEIVEPAGES:
			return { isLoading: false, items: action.items, loaded: true };
		default:
			const exhaustiveCheck: never = action;
	}

	return state;
};
