import { WithLangs } from '@common/typescript/objects/Page';
import { Lang } from '@common/typescript/objects/Lang';

import { Page, PageInfo } from '@commonTuna/react/objects/Page';

export const phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export function capitalizeFirstLetter(string) {
	return string[0].toUpperCase() + string.slice(1);
}

export const sliderSettings = {
	dots: false,
	infinite: true,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
	swipeToSlide: true,
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

export const reviewsSliderSettings = {
	...sliderSettings,
	slidesToShow: 3,
	adaptiveHeight: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};

export const getMoneyString = (value: number, currencyPrefix = '$') => {
	return `${currencyPrefix}${value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const isEmptyOrSpaces = (str: string) => {
	return !str || str.match(/^ *$/) !== null;
};

export const getGoogleMapLink = (address: string): string => {
	return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
};

export const getPageInfo = (item: WithLangs<PageInfo>, lang: Lang): PageInfo | undefined => {
	return item.langs.length > 0 ? (item.langs.find((langItem) => langItem.language === lang) || item.langs[0]) : undefined;
};

export const getPageShortName = (item: Page, lang: Lang): string => {
	return lang === Lang.Es
		? item?.shortNameEs || item?.shortNameEn || item?.name
		: item?.shortNameEn || item?.shortNameEs || item?.name;
};

export const getLangByName = (language: string): Lang => {
	return (Lang[capitalizeFirstLetter(language.toLowerCase())] || Lang.En) as Lang;
};
