import React from 'react';
import { createPortal } from 'react-dom';

import '@common/react/scss/components/base/drawer.scss';

interface Props {
	open: boolean;
	onClose?: (open: boolean) => void;
}

const Drawer: React.FC<Props> = (props) => {
	const {
		open,
		onClose,
	} = props;
	const [currentOpen, setOpen] = React.useState(false);
	const [init, setInit] = React.useState(true);
	const [container, setContainer] = React.useState<HTMLElement | null>();

	React.useEffect(() => {
		if (!container) {
			setContainer(document.getElementById('react-app'));
		}
	}, []);

	React.useEffect(() => {
		if (currentOpen !== open && !init) {
			setOpen(open);
		}
		if (currentOpen !== open && init) {
			setInit(false);
		}
	}, [open, init]);

	if (init || !container) return null;

	return createPortal(<div className={`header-drawer-container header-drawer__${currentOpen ? 'open' : 'close'}`}>
		<div className="header-drawer__mask" onClick={() => onClose && onClose(false)} />
		<div className="header-drawer">
			{props.children}
		</div>
	</div>, container);
};

export default Drawer;
