import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Lang } from '@common/typescript/objects/Lang';
import { useApplicationContext } from '@common/react/components/Core/Application/Application';

interface Props extends LinkProps {
	to: string;
}

const LinkWithLang: React.FC<Props> = ({
	to, children, ...rest
}) => {
	const context = useApplicationContext();
	const language = context?.getLang() ?? Lang.En;
	const link = to[0] === '/' ? to.substring(1) : to;

	return <Link to={language === Lang.Es ? `/es/${link}` : `/${link}`} {...rest}>{children}</Link>;
};

export default LinkWithLang;
