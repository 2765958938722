import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from '@common/react/components/UI/Loading/Loading';

const TranslatedLoading: React.FC = () => {
	const { t } = useTranslation();

	return <Loading caption={t('site.loading')} />;
};

export default TranslatedLoading;
