import { BaseParams } from '@common/typescript/objects/BaseParams';

export const addPrefix = (obj: object, prefix: string) => {
	return Object.entries(obj)
		.reduce((acc, [key, value]) => ({ ...acc, [`${prefix}${key}`]: value }), {});
};

export const removePrefix = (obj: object, prefix: string) => {
	return Object.entries(obj)
		.reduce((acc, [key, value]) => {
			if (key.indexOf(prefix) !== 0 || prefix === '') {
				return { ...acc, [key]: value };
			}
			return { ...acc, [key.slice(prefix.length)]: value };
		}, {});
};

export const filterByPrefix = (obj: object, prefix: string) => {
	return Object.entries(obj)
		.reduce((acc, [key, value]) => (
			key.indexOf(prefix) !== 0
				? acc : { ...acc, [key]: value }
		), {});
};

export const getKeysByPrefix = (obj: BaseParams, prefix?: string): BaseParams => {
	if (!prefix) {
		return obj;
	}

	return removePrefix(filterByPrefix(obj, prefix), prefix);
};
