import * as React from 'react';
import { Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import NotFoundRoute from '@common/react/components/Routes/NotFoundRoute';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import Layout from '@app/components/Layout';
import InitI18n from '@app/components/InitLang';
import { MainRoute } from '@app/components/Routes/MainRoute';

const paramsTranslated = {
	fallback: <Loader />,
};

const base = '/:locale(en|es)?';

const Home = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "HomePage" */
		'@app/components/Pages/Home/Home'
	)), paramsTranslated);
const Contacts = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ContactsPage" */
		'@app/components/Pages/Contacts/Contacts'
	)), paramsTranslated);
const Section = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SectionPage" */
		'@app/components/Pages/Section/Section'
	)), paramsTranslated);
const DoctorList = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorListPage" */
		'@app/components/Pages/DoctorList/DoctorList'
	)), paramsTranslated);
const BeforeAfter = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BeforeAfterPage" */
		'@app/components/Pages/BeforeAfterList/BeforeAfter'
	)), paramsTranslated);
const DoctorPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "DoctorPage" */
		'@app/components/Pages/DoctorList/DoctorPage'
	)), paramsTranslated);
const ClinicPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "ClinicPage" */
		'@app/components/Pages/Clinic/ClinicPage'
	)), paramsTranslated);
const BlogPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "BlogPage" */
		'@app/components/Pages/Blog/Blog'
	)), paramsTranslated);
const FaqListPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "FaqListPage" */
		'@app/components/Pages/Faq/Faq'
	)), paramsTranslated);
const SingleFaq = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "SingleFaqPage" */
		'@app/components/Pages/Faq/FaqPage'
	)), paramsTranslated);
const Specials = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Specials" */
		'@app/components/Pages/Specials/Specials'
	)), paramsTranslated);
const Sale = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "Sale" */
		'@app/components/Pages/Sale/Sale'
	)), paramsTranslated);

export const routes = (
	<InitI18n>
		<Layout>
			<Switch>
				<MainRoute exact path={base} component={Home} />
				<MainRoute exact path={`${base}/home`} component={Home} />
				<MainRoute path={[`${base}/contacts`, `${base}/contactos`]} component={Contacts} />
				<MainRoute path={`${base}/specials/:page?`} component={Specials} />
				<MainRoute path={[`${base}/doctors`, `${base}/practice/doctors`, `${base}/practica/doctors`]} component={DoctorList} />
				<MainRoute path={`${base}/beforeafter`} component={BeforeAfter} />
				<MainRoute path={`${base}/doctor/:path?`} component={DoctorPage} />
				<MainRoute path={`${base}/clinic/:path?`} component={ClinicPage} />
				<MainRoute exact path={`${base}/faq`} component={FaqListPage} />
				<MainRoute path={`${base}/faq/:page`} component={SingleFaq} />
				<MainRoute exact path={`${base}/blog`} component={BlogPage} />
				<MainRoute path={`${base}/sale/:path`} component={Sale} />
				<MainRoute path={`${base}/:path`} component={Section} />
				<NotFoundRoute path="*" openRoute={MainRoute} />
			</Switch>
		</Layout>
	</InitI18n>
);
