import * as React from 'react';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';
import '@common/react/yupLocaleSettings';
import ErrorBoundary from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';
import { LoadingProvider } from '@common/react/components/Core/LoadingProvider/LoadingProvider';
import { NotFoundPageProvider } from '@common/react/components/Core/NotFoundPageProvider/NotFoundPageProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { RequestProvider } from '@common/react/components/RequestProvider/RequestProvider';
import Application from '@common/react/components/Core/Application/Application';

import '@app/scss/style.scss';
import '@app/scss/vendor.scss';
import TranslatedLoading from '@app/components/UI/TranslatedLoading/TranslatedLoading';
import RouteChangeTracker from '@app/components/Routes/RouteChangeTracker';
import CompanySettingsProvider from '@app/components/UI/CompanySettingsProvider';
import { User } from '@app/objects/User';
import { Init } from '@app/objects/Init';

const paramsTranslated = {
	fallback: <Loader />,
};

const CustomNotFoundPage = loadable(() =>
	loadableDelay(import(/* webpackChunkName: "CustomNotFoundPage" */ '@app/components/Pages/NotFoundPage/NotFoundPage')), paramsTranslated);

const Layout: React.FC = ({ children }) => <div className="app-container">
	<ErrorBoundary>
		<CompanySettingsProvider>
			<RouteChangeTracker>
				<RequestProvider ttl={120000} errorComponents={{ notFound: () => <CustomNotFoundPage /> }}>
					<Application<User, Init> withoutSignalR>
						<NotFoundPageProvider notFoundComponent={(() => <CustomNotFoundPage />) as any}>
							<LoadingProvider loader={<TranslatedLoading />}>
								{children}
							</LoadingProvider>
						</NotFoundPageProvider>
					</Application>
				</RequestProvider>
			</RouteChangeTracker>
		</CompanySettingsProvider>
	</ErrorBoundary>
</div>;

export default Layout;
