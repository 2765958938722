/* eslint-disable */
export default {
	en: {
		translation: {
			forms: {
				login: 'Login',
				email: 'Email',
				password: 'Password',
				repeatPassword: 'Repeat password',
				passwordMessage: 'Password successfully changed',
				setupPassword: 'Setup password',
				enterPassword: 'Enter new password',
				firstName: 'First Name',
				lastName: 'Last Name',
				phone: 'Phone',
				forgetPassword: 'Forget password?',
				register: 'Register',
				submit: 'Submit',
				captcha: 'Enter the symbols',
				emailSent: 'An email has been sent to you with further instructions.',
				recoverText: 'Enter the email you provided during registration and we will send you a password reset email',
				recover: 'Recover',
				recoverPassword: 'Recover password',
				'Cosmetic or Dermatology': 'Cosmetic or Dermatology',
				'Procedure of interest': 'Procedure of interest',
				'Location Preference': 'Location Preference',
				'Additional Comments': 'Additional Comments',
				'Age': 'Age',
				'Gender': 'Gender',
				'Height': 'Height',
				'Ethnicity': 'Ethnicity',
				'Weight': 'Weight',
				'Male': 'Male',
				'Female': 'Female',
				'Other': 'Other',
				'Filters': 'Filters',
				'Select doctor': 'Select doctor',
				requestSuccess: 'Your request has been sent successfully.\n We will contact you as soon as possible',
				'Time': 'Time',
				'Date': 'Date of Visit',
				autocomplete: 'Select Service...',
				'Left Implant': 'Left Implant',
				'Right Implant': 'Right Implant',
				'Pre-op Size': 'Pre-op Size',
				'Post-op Size': 'Post-op Size',
				'Reset All': 'Reset All',
				search: 'Search...',
				notFound: 'Not found',
				'Find More': 'Find More',
				characters: 'characters',
				birthDate: 'Date of Birth',
				'Something went wrong': 'Something went wrong',
				Close: 'Close',
			},
			errors: {
				'Required field!': 'Required field!',
				'Required field': 'Required field!',
				'Invalid email': 'Invalid email',
				'Passwords are not equal': 'Passwords are not equal',
				'Invalid phone number': 'Invalid phone number',
				'Must be less than character': 'Must be less than {{max}} character',
				'Failed to load': 'Failed to load'
			},
			site: {
				'Special Offers': 'Special Offers',
				Description: 'Description',
				short: 'Short',
				full: 'Full',
				Videos: 'Videos',
				loading: 'Loading',
				Specials: 'Specials',
				homeHeadline: 'Houston Dermatology <br/>& Plastic Surgery',
				'Plastic Surgery': 'Plastic Surgery',
				'Med Spa': 'Med Spa',
				'Monday': 'Monday',
				'Tuesday': 'Tuesday',
				'Wednesday': 'Wednesday',
				'Thursday': 'Thursday',
				'Friday': 'Friday',
				'Saturday': 'Saturday',
				'Sunday': 'Sunday',
				'Dermatology': 'Dermatology',
				'Before / After': 'Before / After',
				'Service Areas': 'Service Areas',
				'Schedule an Appointment Now': 'Schedule an Appointment',
				'To Main Page': 'To Main Page',
				'Schedule a visit': 'Schedule a visit',
				'Reviews': 'Reviews',
				'Profile': 'Profile',
				'Pages': 'Pages',
				'Services': 'Services',
				'Inquiries': 'Inquiries',
				'Cases': 'Cases',
				'Faqs': 'Faq',
				'Ethnicities': 'Ethnicities',
				'Users': 'Users',
				'Locations': 'Locations',
				'Doctors': 'Doctors',
				'Logout': 'Logout',
				'Reviews from our clients': 'Reviews from our clients',
				'Before and after': 'Before and after',
				'Professions': 'Professions',
				'Dashboard': 'Dashboard',
				'Calendar': 'Calendar',
				'Home': 'Home',
				'Schedule Consultation': 'Schedule Consultation',
				'Contacts': 'Contacts',
				'Read More': 'Read More',
				'Load More': 'Load More',
				'More About': 'More About',
				'Wizard': 'Wizard',
				'Our Team': 'Our Team',
				'Click to see results': 'Click to see results',
				'What can id do': 'WHAT CAN IT DO',
				'Anesthesia': 'Anesthesia',
				'Combine with': 'Combine with',
				'Operation length': 'Operation length',
				'Recovery Time': 'Recovery Time',
				'None': 'None',
				'General': 'General',
				'Sedation': 'Sedation',
				'Regional': 'Regional',
				'Local': 'Local',
				learn: 'learn more',
				'Fast Preview': 'Fast Preview',
				'Buy': 'Buy',
				Products: 'Products',
				Procedures: 'Procedures',
				WARNING: 'WARNING:',
				Providers: 'Providers',
				'This feature contains nudity': 'This feature contains nudity. Please click OK to confirm you are at least 18 years of age and are not offended by such material.'
			},
			seo: {
				homePage: {
					title: 'Houston Dermatology, Plastic Surgery & Med Spa',
					link: '/'
				},
				doctorsPage: {
					title: 'Our Doctors',
					link: '/practice/doctors'
				},
				beforeAndAfterPage: {
					title: 'Plastic Surgery Before & After Photos'
				},
				blogPage: {
					title: 'Blog',
					link: '/blog'
				},
				faqPage: {
					title: 'Faq',
					link: '/faq'
				},
				practicePage: {
					title: 'Practice',
					link: '/practice'
				}
			}
		}
	},
	es: {
		translation: {
			forms: {
				login: 'Inicia sesión',
				email: 'Correo electrónico',
				password: 'Сontraseña',
				repeatPassword: 'Repite la contraseña',
				passwordMessage: 'Contraseña cambiada correctamente',
				setupPassword: 'Configurar contraseña',
				enterPassword: 'Introduzca nueva contraseña',
				firstName: 'Nombre',
				lastName: 'Apellido',
				phone: 'Teléfono',
				forgetPassword: '¿Olvidaste tu contraseña?',
				register: 'Registrate',
				submit: 'Enviar',
				captcha: 'Introduce los símbolos',
				emailSent: 'Se le ha enviado un correo electrónico con más instrucciones.',
				recoverText: 'Ingrese el correo electrónico que proporcionó durante el registro y le enviaremos un correo electrónico de restablecimiento de contraseña',
				recover: 'Recuperar',
				recoverPassword: 'Recuperar contraseña',
				'Cosmetic or Dermatology': 'Cosmética o dermatológica',
				'Procedure of interest': 'Interes en procedimiento',
				'Location Preference': 'Preferente ubicación',
				'Additional Comments': 'Comentarios adicionales',
				'Age': 'Años',
				'Gender': 'Género',
				'Height': 'Altura',
				'Ethnicity': 'Etnicidad',
				'Weight': 'Peso',
				'Male': 'Masculino',
				'Female': 'Femenino',
				'Other': 'Otra',
				'Filters': 'Filtro',
				'Select doctor': 'Seleccionar doctor',
				requestSuccess: 'Su solicitud ha sido enviada con éxito.\n Nos pondremos en contacto con usted tan pronto como sea posible',
				'Time': 'Hora',
				'Date': 'Fecha de visita',
				autocomplete: 'Seleccionar servicio...',
				'Left Implant': 'Implante Izquierdo',
				'Right Implant': 'Implante Derecho',
				'Pre-op Size': 'Tamaño preop.',
				'Post-op Size': 'Tamaño postop.',
				'Reset All': 'Reiniciar Todo',
				search: 'Buscar...',
				notFound: 'Extraviado',
				'Find More': 'Encuentra más',
				characters: 'caracteres',
				birthDate: 'Fecha de nacimiento',
				'Something went wrong': 'Algo salió mal',
				Close: 'Cerca',
				Providers: 'Proveedores',
			},
			errors: {
				'Required field!': '¡Campo requerido!',
				'Required field': '¡Campo requerido!',
				'Invalid email': 'Email inválido',
				'Passwords are not equal': 'Las contraseñas no son iguales',
				'Invalid phone number': 'Numero de telefono invalido',
				'Must be less than character': 'Debe tener menos de {{max}} carácter',
				'Failed to load': 'no se pudo cargar'
			},
			site: {
				'Special Offers': 'Ofertas especiales',
				Description: 'Descripción',
				short: 'Corto',
				full: 'Lleno',
				Videos: 'Vídeos',
				loading: 'Cargando',
				Specials: 'Especiales',
				homeHeadline: 'Houston Dermatología <br/>y Cirugía Plástica',
				'Plastic Surgery': 'Cirujía plástica',
				'Med Spa': 'Spa Médico',
				'Monday': 'Lunes',
				'Tuesday': 'Martes',
				'Wednesday': 'Miércoles',
				'Thursday': 'Jueves',
				'Friday': 'Viernes',
				'Saturday': 'Sábado',
				'Sunday': 'Domingo',
				'Dermatology': 'Dermatología',
				'Before / After': 'Antes / Después',
				'Service Areas': 'Areas de Servicio',
				'Schedule an Appointment Now': 'Calendariza una cita ahora',
				'To Main Page': 'A la página principal',
				'Schedule a visit': 'Programe una visita',
				'Reviews': 'Comentarios',
				'Profile': 'Perfil',
				'Pages': 'Páginas',
				'Services': 'Servicios',
				'Inquiries': 'Consultas',
				'Cases': 'Casos',
				'Faqs': 'Preguntas más frecuentes',
				'Ethnicities': 'Etnias',
				'Users': 'Usuarios',
				'Locations': 'Ubicaciones',
				'Doctors': 'Doctores',
				'Logout': 'Cerrar sesión',
				'Reviews from our clients': 'Telegrama de nuestros clientes',
				'Before and after': 'Antes y después',
				'Dashboard': 'Tablero',
				'Calendar': 'El calendario',
				'Home': 'Casa',
				'Schedule Consultation': 'Consulta de horario',
				'Contacts': 'Contactos',
				'Read More': 'Leer Más',
				'Load More': 'Carga Más',
				'More About': 'Más acerca de',
				'Wizard': 'Wizard',
				'Our Team': 'Nuestro Equipo',
				'Click to see results': 'Click para ver resultados',
				'What can id do': 'QUÉ PUEDE HACER',
				'Anesthesia': 'Anestesia',
				'Combine with': 'Combinar con',
				'Operation length': 'Duración de la operación',
				'Recovery Time': 'Tiempo de recuperación',
				'None': 'Ninguno',
				'General': 'General',
				'Sedation': 'Sedación',
				'Regional': 'Regional',
				'Local': 'Local',
				'Fast Preview': 'Vista previa rápida',
				'Buy': 'Comprar',
				learn: 'aprende más',
				'WARNING': 'ADVERTENCIA:',
				Products: 'Productos',
				Procedures: 'Trámites',
				'This feature contains nudity': 'Esta función contiene desnudos. Haga clic en Aceptar para confirmar que tiene al menos 18 años de edad y que dicho material no le ofende.'
			},
			seo: {
				homePage: {
					title: 'Houston Dermatología, Cirugía Plástica y Med Spa',
					link: '/'
				},
				doctorsPage: {
					title: 'Nuestros doctores',
					link: '/practica/doctors'
				},
				beforeAndAfterPage: {
					title: 'Fotos de antes y después de la cirugía plástica'
				},
				blogPage: {
					title: 'Blog',
					link: '/blog'
				},
				faqPage: {
					title: 'Faq',
					link: '/faq'
				},
				practicePage: {
					title: 'Práctica',
					link: '/practica'
				}
			}
		}
	}
};
/* eslint-enable */
