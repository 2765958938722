export interface List<T> {
	count: number;
	execution: number;
	offset: number;
	list: Array<T>;
}

export type Rows<T> = List<T>;

export const transformArrayToList = (items: any[]): List<any> => ({count: items.length, list: items, offset: 0, execution: 0});

export const isList = (arg: any) => arg.list !== undefined;
