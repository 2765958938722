import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import { Location } from '@commonTuna/react/objects/BaseLocation';

import { capitalizeFirstLetter } from '@app/components/Utils';

interface Props {
	item: Location;
	withoutLink?: boolean;
	color?: string;
}

const LocationName: React.FC<Props> = ({ item, withoutLink, color = '#ecbc3f' }) => {
	const code = item?.shortState;
	const { i18n } = useTranslation();
	const langName: 'En' | 'Es' = capitalizeFirstLetter(i18n.language);

	const name = `${item?.[`name${langName}`]}${code ? `, ${code}` : ''}`;

	return <>
		{' '}
		{item[`path${langName}`] && !withoutLink
			? <LinkWithLang to={`/clinic/${item[`path${langName}`]}`} style={{ color }}>
				{name}
			</LinkWithLang>
			: name}
	</>;
};

export default LocationName;
