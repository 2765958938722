import { HubConnection } from '@microsoft/signalr';

let hub: HubConnection | null = null;

export const getSignalR = (): Promise<HubConnection> => {
	return import('@microsoft/signalr' /* webpackChunkName: "signalR" */).then((signalR) => {
		if (hub) {
			return Promise.resolve(hub);
		}

		hub = new signalR.HubConnectionBuilder()
			.withUrl('/notificationHub')
			.withAutomaticReconnect()
			.build();

		hub.start().catch((err) => console.error(err));

		return Promise.resolve(hub);
	}).catch((e) => {
		console.error(e);
		return Promise.reject(e);
	});
};

export const callWithConnectionOnce = (callback: (connectionId: string | undefined | null) => void) => {
	getSignalR().then((hub) => {
		const hubConnection = (hub as HubConnection);

		if (!hubConnection.connectionId) {
			hubConnection.stop().then(() => hubConnection.start().then(() => callback(hubConnection.connectionId)));
		} else {
			callback(hubConnection.connectionId);
		}
	});
};
