import React from 'react';

import { Props } from 'react-select';

import Select from '@common/react/components/base/BaseSelect/BaseSelect';

export const selectProps = {
	theme: (theme) => ({
		...theme,
		colors: {
			...theme.colors,
			primary25: 'rgba(255, 255, 255, 0.3)',
			primary: 'rgba(238, 174, 21, 0.5)',
		},
	}),
	styles: {
		option: (base, state) => ({
			...base,
			color: state.isSelected ? '#fff !important' : 'inherit',
			backgroundColor: state.isSelected ? '#5a4a2f' : 'inherit',
		}),
	},
};

const BaseSelect: React.FC<Props & { atModal? }> = (props) => {
	return <Select
		{...props}
		theme={(theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary25: 'rgba(255, 255, 255, 0.3)',
				primary: 'rgba(238, 174, 21, 0.5)',
			},
		})}
		styles={{
			option: (base, state) => ({
				...base,
				color: state.isSelected ? '#fff !important' : 'inherit',
				backgroundColor: props.atModal
					? state.isSelected ? '#a89567' : 'inherit'
					: state.isSelected ? '#5a4a2f' : 'inherit',
			}),
		}}
	/>;
};

export default BaseSelect;
