import React from 'react';
import { createPortal } from 'react-dom';

import '@common/react/scss/components/base/modal.scss';

interface Props {
	open: boolean;
	className?: string;
	onClose?: (open: boolean) => void;
	style?: React.CSSProperties;
	title?: React.ReactNode;
}

const Drawer: React.FC<Props> = (props) => {
	const {
		open,
		onClose,
		style,
		title,
		className = '',
	} = props;
	const [currentOpen, setOpen] = React.useState(false);
	const [init, setInit] = React.useState(true);
	const [container, setContainer] = React.useState<HTMLElement | null>();

	React.useEffect(() => {
		if (!container) {
			setContainer(document.getElementById('react-app'));
		}
		return () => {
			if (typeof document === 'undefined') return;
			const elem = document.body;
			elem.style.overflowY = 'auto';
		};
	}, []);

	React.useEffect(() => {
		if (typeof document === 'undefined') return;

		const elem = document.body;

		elem.style.overflowY = open ? 'hidden' : 'auto';
	}, [open]);

	React.useEffect(() => {
		if (currentOpen !== open && !init) {
			setOpen(open);
		}
		if (currentOpen !== open && init) {
			setInit(false);
		}
	}, [open, init]);

	if (init || !container) return null;

	return createPortal(<div className={`modal-container modal__${currentOpen ? 'open' : 'close'}`}>
		<div className="modal__mask" onClick={() => onClose && onClose(false)} />
		<div className={`modal ${className}`} style={style}>
			<button
				type="button"
				className="modal-close"
				onClick={() => onClose && onClose(false)}
			>
				<i className="fa fa-times" />
			</button>
			{title && <div className="modal-header">
				{title}
			</div>}
			<div className="modal-body">
				{props.children}
			</div>
		</div>
	</div>, container);
};

export default Drawer;
