import { List } from '@common/typescript/objects/List';
import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Nullable } from '@common/typescript/objects/Nullable';
import { IPublished } from '@common/typescript/objects/IPublished';
import { IAvatarTarget } from '@common/typescript/objects/IAvatarTarget';
import { Page as BasePage, PageInfo as BasePageInfo } from '@common/typescript/objects/Page';
import { WithFile } from '@common/typescript/objects/WithFile';

import { CasePage } from '@commonTuna/react/objects/BaseCase';
import { FaqPage } from '@commonTuna/react/objects/FaqPage';
import { ReviewPage } from '@commonTuna/react/objects/ReviewPage';
import { Review } from '@commonTuna/react/objects/Review';

export interface PagePage {
	parent: Page;
	id: number;
	deleted: boolean;
	pageId: number;
	parentId: number;
}

export enum FactsBlock
{
	None = 0,
	Left = 1,
	Right = 2
}

export enum Anesthesia
{
	None = 0,
	General = 1,
	Sedation = 2,
	Regional = 3,
	Local = 4
}

export enum PageType {
	PAGE = 0,
	SERVICE = 1
}

export interface PageInfo extends BasePageInfo {
	shortBody: string;
}

export interface LangsInfo {
	titleEn: string;
	titleEs: string;
	textEn: string;
	textEs: string;
}

export interface PageImage extends WithFile<Page>, LangsInfo {

}

export interface PageVideo extends WithDeleted, LangsInfo {
	page: Page;
	pageId: number;
	url: string;
}

export const rootPageId = 3;

export interface Page extends WithDeleted, IPublished, BasePage, IAvatarTarget {
	duration: string;
	shortNameEn: string;
	shortNameEs: string;
	tagLineEn: string;
	tagLineEs: string;
	basePath?: string;
	show: boolean;
	menu: boolean;
	text: string;
	children: List<Page>;
	pages: List<PagePage>;
	fullPath: string;
	pageType: PageType;
	files: Array<PageImage>;
	videos: Array<PageVideo>;
	surgeryLength: string;
	recoveryTime: string;
	cases: Array<CasePage>;
	faqs: Array<FaqPage>;
	reviews: Array<ReviewPage>;
	randomReviews: Array<Review>;
	parentPage: Nullable<Page>;
	pathEs: string;
	fullPathEs: string;
	langs: Array<PageInfo>;
}
