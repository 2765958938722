import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { debounce } from '@common/typescript/Utils';

interface Props extends WithTranslation {
	onClick: () => void;
}

const BottomButton: React.FC<Props> = ({ t, onClick }) => {
	const [buttonVisible, changeButtonVisibility] = React.useState<boolean>(false);

	React.useEffect(
		() => {
			const handleScroll = () => {
				const header = document.querySelector('.header-container');

				if (header) {
					changeButtonVisibility(window.scrollY > header.clientHeight + 15);
				}
			};

			window.addEventListener('scroll', debounce(handleScroll, 300));

			return () => window.removeEventListener('scroll', debounce(handleScroll, 300));
		},
		[debounce],
	);

	return <div className={`pull-left mobile-hidden schedule-bottom-btn-container ${
		buttonVisible ? 'schedule-bottom-btn-container_show' : ''}`}
	>
		<button type="button" className="btn schedule-bottom-btn animated heartBeat infinite" onClick={onClick}>
			{t('site.Schedule Consultation')}
		</button>
	</div>;
};

export default withTranslation()(BottomButton);
