import * as React from 'react';

import loadable from '@loadable/component';

import { loadableDelay } from '@common/react/loadable/loadableSettings';

import Header from '@app/components/UI/Header/Header';
import Footer from '@app/components/UI/Footer/Footer';

const FormStyle = loadable(
	() =>
		loadableDelay(import(/* webpackChunkName: "FormStyle" */ '@app/components/Forms/ContactUs/FormStyles')),
	{ fallback: <></> },
);

export const MainLayout: React.FC = (props) => {
	const [showPodium, setShowPodium] = React.useState(false);

	React.useEffect(() => {
		if (showPodium) {
			const script = document.getElementById('podium-widget');

			if (!script) {
				const script = document.createElement('script');

				script.src = 'https://connect.podium.com/widget.js#API_TOKEN=087a7109-04c1-4a4e-a315-f789368a2d06';
				script.async = true;
				script.id = 'podium-widget';
				script.setAttribute('data-api-token', '087a7109-04c1-4a4e-a315-f789368a2d06');

				document.body.appendChild(script);
			}
		}
	}, [showPodium]);

	const handler = React.useCallback(() => setShowPodium(true), []);

	React.useEffect(() => {
		window.addEventListener('scroll', () => {
			handler();
			window.removeEventListener('scroll', handler);
		});
	}, []);

	return <div
		onPointerMove={handler}
		onPointerEnter={handler}
		onPointerDown={handler}
		className="content"
	>
		{showPodium ? <FormStyle /> : null}
		<Header />
		<div className="content">
			{props.children}
		</div>
		<Footer />
	</div>;
};
