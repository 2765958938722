import * as React from 'react';
import { withTranslation } from 'react-i18next';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import SocialIcons from '@app/components/UI/SocialIcons/SocialIcons';

const Footer: React.FC = (props) => {
	return <footer className="site-footer">
		<div className="container">
			<SocialIcons className="site-footer-icons" svgIconFill="#777277" />
			<div className="site-footer-menu">
				<a href="https://www.northerntuna.com/" target="_blank" rel="noreferrer">
					<ImageLazy
						width={200}
						height={35}
						alt="Tuna Logo"
						src="/images/logo-350x62.png"
					/>
				</a>
			</div>
			<div className="site-footer-copyright">
				©Dr. Eric Humble
			</div>
		</div>
	</footer>;
};

export default withTranslation()(Footer);
