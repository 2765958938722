import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { I18nextProvider } from 'react-i18next';

import { Lang } from '@common/typescript/objects/Lang';

import { initI18n } from '@app/i18n';
import { ApplicationState } from '@app/store';

const InitI18n: React.FC = ({ children }) => {
	const lang = useSelector((state: ApplicationState) => state.login.lang, shallowEqual);
	return <I18nextProvider i18n={initI18n(lang === Lang.Es ? 'es' : 'en')}>
		{children}
	</I18nextProvider>;
};

export default InitI18n;
