import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import ErrorBoundary from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

import { MainLayout } from '@app/components/Layouts/MainLayout';

interface Props extends RouteProps {
	component: any;
}

export const MainRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			<MainLayout>
				<ErrorBoundary>
					<Component {...props} />
				</ErrorBoundary>
			</MainLayout>}
	/>
);
