import { BaseUser } from '@common/react/objects/BaseUser';
import {
	LoginState as BaseLoginState,
	LoginActionCreators as BaseLoginActionCreators,
	getActionCreators as baseGetActionCreators,
	getReducer as baseGetReducer,
} from '@common/react/store/Login';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store/index';

export { TypeKeys } from '@common/react/store/Login';

export type LoginState = BaseLoginState<User>

export type LoginActionCreators = BaseLoginActionCreators<BaseUser, ApplicationState>

export function getActionCreators() {
	return baseGetActionCreators<BaseUser, ApplicationState>();
}

export function getReducer() {
	return baseGetReducer<User>();
}
