import * as React from 'react';

import FormikField, { FormikFieldProps } from '@common/react/components/Forms/FormikField/FormikField';

import { TranslatedErrorMessage } from '@app/components/UI/TranslatedErrorMessage/TranslatedErrorMessage';

const TranslatedFormikField: React.FC<FormikFieldProps> = (props) => {
	return <FormikField {...props} ErrorComponent={TranslatedErrorMessage} />;
};

export default TranslatedFormikField;
