import React, { PropsWithChildren } from 'react';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import {
	ItemsProvider,
	useItemsProviderContext,
	ItemsProviderProps,
	createItemsProviderContext,
	defaultTransformFiltersBeforeHandleUrl,
	SortingDirection,
	ItemsProviderContextActions,
	WithKey, ItemsProviderContext,
} from '@common/react/components/Core/ItemsProvider/ItemsProvider';
import { BaseParams } from '@common/typescript/objects/BaseParams';
import { getSearchParamsFromUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

export interface AdvancedItemsProviderProps<T extends WithDeleted> extends ItemsProviderProps<T> {
	filterHandler: (item, filters) => boolean | Promise<boolean>;
	defaultSort?: [string, SortingDirection];
	sortHandler?: (item1, item2, filters) => number; // return number as function for Array.sort
}

interface AdvancedItemsHandlerProps {
	filterHandler: (item, filters) => any;
	defaultSort?: [string, SortingDirection];
	sortHandler?: (item1, item2, filters) => number;
	add?: (items: Array<any>) => any;
	addedFirst?: boolean;
}

export interface AdvancedItemsProviderContextActions<T extends WithKey> extends ItemsProviderContextActions<T> {
	addItem: (item: T) => void;
	reloadItems: (params?: BaseParams) => void;
	deleteItem: (item: Omit<Partial<T>, 'id'> & WithDeleted) => void;
}

export const checkFilterProp = (value: any) => {
	if (value === undefined || value === null || value === '') {
		return false;
	}
	if (typeof value === 'number') {
		return value > -1;
	}
	if (typeof value === 'string') {
		if (!isNaN(+value)) {
			return +value > -1;
		}
	}
	if (Array.isArray(value)) {
		return value.length > 0;
	}
	return true;
};

export const findId = (array, id) => {
	return array.find((i) => +i === +id);
};

const defaultSortHandler = (item1, item2, filters, defaultSort: [string, SortingDirection]) => {
	const { column } = filters;
	if (column) {
		const [prop, type] = column;
		if (type === SortingDirection.Ascending) {
			return item1[prop] - item2[prop];
		}
		if (type === SortingDirection.Descending) {
			return item2[prop] - item1[prop];
		}
	}
	const [prop, type] = defaultSort;
	if (type === SortingDirection.Ascending) {
		return item1[prop] - item2[prop];
	}
	if (type === SortingDirection.Descending) {
		return item2[prop] - item1[prop];
	}
	return 0;
};

export const useAdvancedItemsProviderContext = <T extends WithKey, >() => useItemsProviderContext<T, AdvancedItemsProviderContextActions<T>>();

const AdvancedItemsHandler = <T extends WithDeleted>(p: PropsWithChildren<AdvancedItemsHandlerProps>) => {
	const {
		children,
		filterHandler,
		sortHandler,
		defaultSort = ['sortOrder', SortingDirection.Ascending],
	} = p;
	const ItemsContext = createItemsProviderContext<T>();

	const context = useItemsProviderContext<T>();

	if (!context.state) throw 'Need ItemsProvider context!';

	const {
		state: {
			items: itemsProp, pagination, filters, loading, loaders, edits, errors, error,
		},
		actions: {
			setItems, reload, load, setEdit,
		},
	} = context;

	const [id, setId] = React.useState(-1);

	const value = React.useMemo(() => {
		const { pageSize, current } = pagination;

		const isFirstPage = current === 1;

		const items = itemsProp.slice(isFirstPage ? 0 : 1)
			.slice(0, pageSize - 2 + Object.keys(edits)
				.filter((key) => +key < 0).length);

		const handledPagination = {
			...(pagination || { current: 1, pageSize: 10, total: 0 }),
			pageSize: pagination.pageSize ? pagination.pageSize - 2 : 10,
		};

		const handleReload = (params) => {
			const pageSize = params?.pageSize || pagination.pageSize - 2;
			const current = params?.current || pagination.current;
			const isFirstPage = current === 1;

			return reload({
				...params,
				pageSize: params?.pageSize ? params.pageSize + 2 : undefined,
				offset: isFirstPage ? 0 : pageSize * (current - 1) - 1,
			});
		};

		const addItem = (item) => {
			Promise.resolve(filterHandler(item, filters)).then((res) => {
				if (res) {
					const newItems = sortHandler || defaultSort
						? itemsProp.concat(item)
							.sort((item1, item2) =>
								(sortHandler ? sortHandler(item1, item2, filters)
									: defaultSortHandler(item1, item2, filters, defaultSort)))
						: itemsProp.concat(item);
					if (itemsProp.length > pagination.pageSize) {
						newItems.pop();
					}
					setItems(newItems);
				}
			});
		};

		const add = (item?: any) => {
			setId((id) => id - 1);

			const newItem = item ? { ...item, id } : { ...p.add?.(itemsProp), id };

			const newItems = p.addedFirst
				? isFirstPage ? [newItem].concat(itemsProp) : [...itemsProp.slice(0, 1), newItem, ...itemsProp.slice(1)]
				: [
					...itemsProp.slice(0, itemsProp.length - (isFirstPage ? 2 : 1)),
					newItem,
					...itemsProp.slice(itemsProp.length - (isFirstPage ? 2 : 1)),
				];

			setItems(newItems);

			setEdit(newItem);
			return newItem;
		};

		const deleteItem = (id) => {
			setItems(itemsProp.filter((item) => item.id !== id));
		};

		const reloadItems = () => {
			return load({ ...pagination, pageSizeOptions: undefined }, true);
		};

		const updateItem = (item) => {
			setItems(itemsProp.map((el) => (el.id === item.id ? { ...el, ...item } : el)));
		};

		return {
			state: {
				...context.state,
				items,
				pagination: handledPagination,
				advancedItems: itemsProp,
			},
			actions: {
				...context.actions,
				reload: handleReload,
				addItem,
				reloadItems,
				updateItem,
				deleteItem,
				add,
			},
		};
	}, [itemsProp, pagination, loading, filters, loaders, edits, errors, error, p.add]);

	return (
		<ItemsContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</ItemsContext.Provider>
	);
};

const AdvancedItemsProvider = <T extends WithDeleted>(p: AdvancedItemsProviderProps<T>) => {
	const { children } = p;

	const pagination = {
		...(p.pagination || { current: 1, pageSize: 12, total: 0 }),
		pageSize: p.pagination?.pageSize ? p.pagination.pageSize + 2 : 12,
	};

	const transformFiltersBeforeHandleUrl = (filters) => {
		const urlFilters = defaultTransformFiltersBeforeHandleUrl({
			...filters,
			pageSize: filters.pageSize ? filters.pageSize - 2 : undefined,
			count: filters.pageSize || filters.count ? (filters.count || filters.pageSize) - 2 : undefined,
		});

		return p.transformFiltersBeforeHandleUrl ? p.transformFiltersBeforeHandleUrl(urlFilters) : urlFilters;
	};

	const searchParamsFromUrl = (location, prefix?: string) => {
		const params = getSearchParamsFromUrl(location, prefix);
		const pageSize = params[`${prefix || ''}pageSize`];

		return {
			...params,
			[`${prefix || ''}pageSize`]: pageSize ? pageSize + 2 : 12,
		};
	};

	return (
		<ItemsProvider
			{...p}
			filters={{ ...p.filters, count: pagination.pageSize }}
			transformFiltersBeforeHandleUrl={transformFiltersBeforeHandleUrl}
			pagination={pagination}
			searchParamsFromUrl={searchParamsFromUrl}
		>
			<AdvancedItemsHandler
				defaultSort={p.defaultSort}
				filterHandler={p.filterHandler}
				sortHandler={p.sortHandler}
				addedFirst={p.addedFirst}
				add={p.add}
			>
				{children}
			</AdvancedItemsHandler>
		</ItemsProvider>
	);
};

export default AdvancedItemsProvider;
