import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// eslint-disable-next-line
import once from 'lodash/once';
// eslint-disable-next-line
import isEmpty from 'lodash/isEmpty';

import { Company } from '@commonTuna/react/objects/Company';

import { ApplicationState } from '@app/store';

export interface CompanySettingsContext {
	companySettings: Company;
}

export const createCompanySettingsContext = once(() => React.createContext({} as CompanySettingsContext));

export const useCompanySettingsContext: () => CompanySettingsContext = () => {
	const context: CompanySettingsContext = React.useContext(createCompanySettingsContext());

	if (isEmpty(context)) throw 'Need CompanySettings context!';

	return context;
};

const CompanySettingsProvider: React.FC = ({
	children,
}) => {
	const CompanySettingsContext = createCompanySettingsContext();

	const companySettings = useSelector((state: ApplicationState) => state.companySettings.item, shallowEqual);

	return (
		<>
			<CompanySettingsContext.Provider value={{ companySettings }}>
				{children}
			</CompanySettingsContext.Provider>
		</>
	);
};

export default CompanySettingsProvider;
