export enum InsuranceType {
	Medicare = 0,
	Medicaid = 1,
	Tricare = 2,
	Champva = 3,
	GroupHealthPlan = 4,
	FecaBlkLung = 5,
	Commercial = 6
}

export const InsuranceTypeNames = {
	[InsuranceType.Medicare]: 'MEDICARE',
	[InsuranceType.Medicaid]: 'MEDICAID',
	[InsuranceType.Tricare]: 'TRICARE',
	[InsuranceType.Champva]: 'CHAMPVA',
	[InsuranceType.GroupHealthPlan]: 'GROUP HEALTH PLAN',
	[InsuranceType.FecaBlkLung]: 'FECA BLK LUNG',
	[InsuranceType.Commercial]: 'COMMERCIAL',
};

export enum Gender {
	Male = 0,
	Female = 1,
	Other = 2,
	None = 3
}

export const GenderNames = {
	[Gender.Male]: 'Male',
	[Gender.Female]: 'Female',
	[Gender.Other]: 'Other',
	[Gender.None]: 'None',
};

export enum AvailableNumbersSearchType {
	StartsWith = 0,
	Anywhere = 1,
	EndsWith = 2
}

export const AvailableNumbersSearchTypeNames = {
	[AvailableNumbersSearchType.Anywhere]: 'Anywhere',
	[AvailableNumbersSearchType.StartsWith]: 'Starts with',
	[AvailableNumbersSearchType.EndsWith]: 'Ends with',
};

export enum NotificationsType {
	Sms,
	Email
}

export const NotificationTypeNames = {
	[NotificationsType.Sms]: 'Sms',
	[NotificationsType.Email]: 'Email',
};

export enum MailboxType {
	Smtp = 0,
	Imap = 1
}

export enum PublishedFilterEnum {
	All = 0,
	Published = 1,
	Unpublished = 2
}

export const publishedFilterGroup = [
	{ caption: 'Published', value: PublishedFilterEnum.Published },
	{ caption: 'Unpublished', value: PublishedFilterEnum.Unpublished },
	{ caption: 'All', value: PublishedFilterEnum.All },
];
