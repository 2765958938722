import { ReducersMapObject } from 'redux';

import * as Items from '@common/react/store/ItemList';
import * as Item from '@common/react/store/Item';
import { BaseApplicationState, baseReducers } from '@common/react/store';
import { PageItemState, reducer as PageStateReducer } from '@common/react/store/PageItem';
import { BuildData } from '@common/react/objects/BuildData';
import { ItemsState as ItemsProviderStoreState, getReducer as getIPStoreReducer } from '@common/react/store/ItemsProviderStore';

import { Location } from '@commonTuna/react/objects/BaseLocation';
import { Doctor } from '@commonTuna/react/objects/BaseDoctor';
import { Company } from '@commonTuna/react/objects/Company';

import { User } from '@app/objects/User';
import { MenuState, reducer as MenuReducer } from '@app/store/Menu';

// The top-level state object
export interface ApplicationState extends Omit<BaseApplicationState<User>, 'chat'> {
	serverPage: PageItemState<any>;

	buildData: Item.ItemState<BuildData>;
	initDoctors: ItemsProviderStoreState<Doctor>;

	menu: MenuState;

	offices: Items.ItemsState<Location>;

	companySettings: Item.ItemState<Company>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers: ReducersMapObject = {
	...baseReducers,

	serverPage: PageStateReducer<any>('serverPage'),
	initDoctors: getIPStoreReducer<Doctor>('initDoctors'),

	menu: MenuReducer,

	offices: Items.getReducer<Location>('offices'),

	companySettings: Item.getReducer<Company>('companySettings'),
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
