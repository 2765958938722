import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const TranslatedErrorMessage: React.FC<{ error: any } > = ({ error }) => {
	const { t } = useTranslation();
	let key = '';
	let max = null;

	if (typeof error !== 'string') {
		key = error.key;
		max = error.value;
	}

	return <div className="validation-message">
		{typeof error !== 'string'
			? t(`errors.${key}`, { max })
			: t(`errors.${error}`)}
	</div>;
};
