import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { useTranslation } from 'react-i18next';

import { bindActionCreators } from 'redux';

import { Lang } from '@common/typescript/objects/Lang';
import { MenuItem } from '@common/react/objects/MenuItem';
import { replaceExceptNumber } from '@common/typescript/Utils';
import { parseQuery } from '@common/typescript/utils/url';
import { transformArrayToList } from '@common/typescript/objects/List';
import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import Drawer from '@common/react/components/base/Drawer/Drawer';
import Modal from '@common/react/components/base/Modal/Modal';
import LinkWithLang from '@common/react/components/UI/lang/LinkWithLang/LinkWithLang';

import { Page } from '@commonTuna/react/objects/Page';

import { ApplicationState } from '@app/store';
import * as LoginState from '@app/store/Login';
import { getGoogleMapLink, getPageShortName } from '@app/components/Utils';
import SocialIcons from '@app/components/UI/SocialIcons/SocialIcons';
import BottomButton from '@app/components/UI/BottomButton/BottomButton';
import HeaderSearch from '@app/components/UI/Header/HeaderSearch';
import LocationName from '@app/components/UI/LocationName/LocationName';
import { Menu as CustomMenu } from '@app/components/UI/Header/Menu';
import ContactUsForm from '@app/components/Forms/ContactUs/ContactUsForm';

const langImg = [
	{
		lang: Lang.En,
		image: '/images/united-states.png',
		title: 'English',
	},
	{
		lang: Lang.Es,
		image: '/images/spain.webp',
		title: 'Spanish',
	},
];

const transformMenuRecursively = (items: Array<Page>, lang: Lang, checkSubChildren?: boolean) => {
	return items.map((item) => {
		const newItem: Partial<MenuItem> = {
			path: lang === Lang.Es ? `/es/${item.fullPath}` : `/${item.fullPath}`,
			name: getPageShortName(item, lang),
			exact: true,
		};

		if (item.children && item.children.list) {
			if (item.children.list.length > 12) {
				newItem.className = 'three-columns-menu';
			}

			if (checkSubChildren && item.children.list.some((child) => child.children && child.children.list && child.children.list.length > 0)) {
				newItem.className = 'with-sub-children';
			}

			newItem.children = {
				...item.children,
				list: transformMenuRecursively(item.children.list, lang),
			};
		} else {
			newItem.children = transformArrayToList([]);
		}

		return newItem as MenuItem;
	});
};

const Header: React.FC = () => {
	const menuContainer = React.useRef<HTMLDivElement>(null);
	const [isMenuOpen, setMenuOpen] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	const ref = React.useRef<HTMLDivElement>(null);

	const history = useHistory();

	const dispatch = useDispatch();
	const loginActions = React.useMemo(() => bindActionCreators(LoginState.getActionCreators(), dispatch), []);

	const { i18n, t } = useTranslation();
	const { lang, menu, offices } = useSelector((state: ApplicationState) => ({
		lang: state.login.lang,
		menu: state.menu,
		offices: state.offices.items,
	}));

	React.useEffect(() => {
		window.scrollTo(0, 0);
		setMenuOpen(false);
		menuContainer.current && menuContainer.current.classList.toggle('closed-menu');
		setTimeout(() => menuContainer.current?.classList.toggle('closed-menu'), 0);
	}, [history.location.pathname, parseQuery(history.location.search).page]);

	const changeLang = (lng: string) => {
		const lang = Lang[lng];

		i18n.changeLanguage(lng.toLowerCase());
		loginActions.setLang(lang);
	};

	const menuItems = React.useMemo(() => {
		return transformMenuRecursively(menu.items, lang, true);
	}, [menu.items, lang]);

	const toggleMenu = () => setMenuOpen((prev) => !prev);

	const openDialog = React.useCallback(() => {
		setModalVisible(true);
	}, []);
	const closeDialog = React.useCallback(() => setModalVisible(false), []);
	const handleLogoClick = () => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return <>
		<div ref={ref} />
		<div className="header-container">
			<Sticky stickyClassName="header-sticky" holderProps={{ className: 'header-sticky-wrapper' }}>
				<header className="site-header">
					<div className="site-header__top">
						<div className="container">
							<div className="clearfix">
								<div className="site-header__left">
									<div className="site-header__logo">
										<LinkWithLang
											onClick={handleLogoClick}
											to="/#home-top"
										>
											<ImageLazy width={48} height={45} src="/images/logo-min.webp" alt="Beauty Becomes" />
										</LinkWithLang>
									</div>
								</div>
								<div className="site-header-right">
									<div className="site-header-phones">
										{offices.length > 0 ? offices.map((item, index) => <React.Fragment key={item.id}>
											{index > 0 && <span className="site-header-phones__line" />}
											<span>
												<SocialIcons className="site-header-icons" locationId={item.id} />
												<LocationName item={item} color="#342e20" />
&nbsp;
												<a href={`tel:${replaceExceptNumber(phoneFormat(item.phone))}`}>{phoneFormat(item.phone)}</a>
											</span>
										</React.Fragment>) : <span>&nbrp;</span>}
									</div>
									<div className="site-header-lang">
										{
											langImg.map((item) => <span
												key={item.lang}
												title={item.title}
												className={`mobile-header-lang__item ${item.lang === lang
													? 'mobile-header-lang__item__selected' : ''}`}
												onClick={() => changeLang(Lang[lang] === 'Es' ? 'En' : 'Es')}
											>
												<img width="64" height="42" src={item.image} alt="change language" />
											</span>)
										}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="site-header__bottom">
						<div className="container">
							<div className="is-relative clearfix">
								<div className="pull-left" ref={menuContainer}>
									<CustomMenu
										renderSubmenuOnHover
										className="site-header-menu"
										items={menuItems.concat({
											path: lang === Lang.Es ? '/es/specials' : '/specials',
											ariaLabel: 'Sale',
											name: <span className="menu-icon">
												<img
													width={40}
													height={40}
													src="/icons/sale-badge.png"
													alt="Sale"
												/>
												<span>
													Sale
												</span>
											</span>,
											exact: true,
										} as any)}
									/>
								</div>
								<div className="pull-right site-header__bottom-right" style={{ float: 'right' }}>
									<HeaderSearch />
									<button
										type="button"
										aria-label={t('site.Schedule Consultation')}
										className="site-header-book-button"
										onClick={openDialog}
									>
										<i className="fa fa-calendar" />
										<span className="site-header-book-button__text">
											{t('site.Schedule Consultation')}
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</header>
			</Sticky>
			<div className="mobile-header">
				<div className="mobile-header__top clearfix">
					<LinkWithLang
						onClick={handleLogoClick}
						to="/#home-top"
						className="mobile-header__logo"
					>
						<img
							width="215"
							height="55"
							src="/images/logo.webp"
							alt="Beauty Becomes logo"
						/>
					</LinkWithLang>
					<button
						aria-label={`${isMenuOpen ? 'close' : 'open'} menu`}
						className="menu-burger"
						type="button"
						onClick={toggleMenu}
					>
						<span className={`menu-burger__span ${isMenuOpen ? 'menu-burger__span_open' : ''}`} />
					</button>
					<button
						type="button"
						aria-label={t('site.Schedule Consultation')}
						className="site-header-book-button mr10 pull-right btn-sm animated heartBeat infinite"
						onClick={openDialog}
					>
						<i className="fa fa-calendar" />
					</button>
					<div className="mobile-header-lang pull-right">
						{
							langImg.map((item) => <span
								key={item.lang}
								title={item.title}
								className={`mobile-header-lang__item ${item.lang === lang ? 'mobile-header-lang__item__selected' : ''}`}
								onClick={() => changeLang(Lang[lang] === 'Es' ? 'En' : 'Es')}
							>
								<img width="64" height="42" src={item.image} alt="change language" />
							</span>)
						}
					</div>
				</div>
			</div>
		</div>
		<Drawer
			onClose={toggleMenu}
			open={isMenuOpen}
		>
			<ul className="mobile-menu menu-component offices-menu mobile-header__phones">
				{offices.map((item) => (
					<li key={item.id} className="menu-component__item">
						<div className="mobile-header__phones-left">
							<a href={getGoogleMapLink(item.addressEn)} target="_blank" rel="noreferrer">
								<i className="fa fa-map-marker map-icon" />
								{' '}
								<LocationName item={item} withoutLink />
							</a>
						</div>
						<div className="">
							<a href={`tel:${replaceExceptNumber(item.phone)}`}>
								<i className="fa fa-phone phone-icon" />
								{' '}
								{phoneFormat(item.phone)}
							</a>
						</div>
						<SocialIcons className="site-header-icons" locationId={item.id} />
					</li>
				))}
				<li key="button" className="menu-component__item menu-component__button">
					<button
						type="button"
						aria-label={t('site.Schedule Consultation')}
						className="site-header-book-button btn-sm"
						onClick={() => {
							toggleMenu();
							openDialog();
						}}
					>
						<i className="fa fa-calendar" />
&nbsp;
						{t('site.Schedule Consultation')}
					</button>
				</li>
				<li key="button" className="menu-component__item menu-component__button">
					<NavLink
						to={lang === Lang.Es ? '/es/specials' : '/specials'}
						className="site-header-book-button btn-sm"
					>
						<img
							width={40}
							height={40}
							className="specials-img"
							src="/icons/sale-badge.png"
							alt="specials"
						/>
						&nbsp;
						{t('site.Special Offers')}
					</NavLink>
				</li>
			</ul>
			<CustomMenu className="mobile-menu" items={menuItems} />
		</Drawer>
		<Modal
			title={t('site.Schedule an Appointment Now')}
			open={modalVisible}
			style={{ width: 'min(950px, 100vw - 50px, 100%)' }}
			onClose={closeDialog}
		>
			<ContactUsForm className="general-form" onSave={closeDialog} atModal />
		</Modal>
		<BottomButton onClick={openDialog} />
	</>;
};

export default Header;
