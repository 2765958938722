import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { MenuItem } from '@common/react/objects/MenuItem';
import '@common/react/scss/components/menu.scss';

interface MenuItemProps extends Omit<MenuProps, 'items' | 'className'>{
	item: MenuItem & { ariaLabel?: string };
	renderSubmenuOnHover?: boolean;
}

interface MenuProps {
	className?: string;
	items: Array<MenuItem>;
	withChildren?: boolean;
	basePath?: string;
	pathKey?: string;
	defaultOpen?: boolean;
	renderSubmenuOnHover?: boolean;
}

export const Item: React.FC<MenuItemProps> = (props) => {
	const {
		defaultOpen, item, basePath, pathKey, withChildren, renderSubmenuOnHover,
	} = props;
	const [open, setOpen] = React.useState<boolean>(defaultOpen || (typeof item.isOpen !== 'undefined' ? item.isOpen : false));
	const [showSubmenu, setShowSubmenu] = React.useState(!renderSubmenuOnHover);

	const toggleMenu = () => setOpen((prev) => !prev);

	const condition = withChildren && item.children && item.children.list && item.children.list.length > 0;

	const path = item[pathKey || 'path'];

	const { exact = false, isActive } = item;

	const className = `menu-component__item ${
		open && condition ? 'menu-component__item_open' : ''
	} ${
		condition ? 'menu-component__item_with-children' : ''
	} ${item.className || ''}`;

	return (
		<li className={className} onPointerEnter={() => setShowSubmenu(true)}>
			{path ? item.externalLink
				? (
					<a aria-label={item.ariaLabel} className="menu-component__item-link" href={item.path} target="_blank" rel="noreferrer">
						{item.name}
					</a>
				)
				: (
					<NavLink
						exact={exact}
						to={basePath + path}
						className="menu-component__item-link"
						activeClassName="menu-component__item-link_active"
						isActive={isActive}
						aria-label={item.ariaLabel}
					>
						{item.name}
					</NavLink>
				)
				: (
					<span
						className="menu-component__item-link"
						onClick={toggleMenu}
						style={{ cursor: 'pointer' }}
					>
						{item.name}
					</span>
				)
			}
			{condition && showSubmenu
				&& <>
					{!renderSubmenuOnHover && <i
						className={`icons menu-component__item-toggle ${open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`}
						onClick={toggleMenu}
					/>}
					<div className={`menu-component__item-children-container ${open ? 'isOpen' : ''}`}>
						<ul className="menu-component__children">
							{item.children && item.children.list.map((child, index) =>
								<Item
									item={child}
									key={index}
									withChildren={withChildren}
									basePath={child.basePath || basePath || ''}
									pathKey={pathKey}
								/>)}
						</ul>
					</div>
				</>
			}
		</li>
	);
};

export const Menu: React.FC<MenuProps> = (props) => {
	const {
		className = '', items, withChildren = false, basePath, pathKey, defaultOpen, renderSubmenuOnHover,
	} = props;
	const menuItems = items || [];

	return <ul className={`menu-component ${className}`}>
		{menuItems.map((item, index) =>
			<Item
				defaultOpen={defaultOpen}
				key={index}
				item={item}
				withChildren={item.children ? true : withChildren}
				basePath={item.basePath || basePath || ''}
				pathKey={pathKey}
				renderSubmenuOnHover={renderSubmenuOnHover}
			/>)}
	</ul>;
};
