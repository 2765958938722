import React from 'react';
import Select, { Props } from 'react-select';

export interface BaseSelectProps extends Props {
	prefix?: string;
}

const BaseSelect: React.FC<BaseSelectProps> = (props) => {
	const value = typeof props.value === 'number' ? props.options?.find((item: any) => item?.value === props.value) : props.value;
	const {
		prefix = 'general-form-select',
		...rest
	} = props;

	return <Select
		{...rest}
		value={value}
		isSearchable={props.isSearchable ?? false}
		classNames={{
			menu: () => `${prefix}-dropdown`,
			clearIndicator: () => `${prefix}-clear`,
			dropdownIndicator: () => `${prefix}-indicator`,
			control: () => `${prefix}-control`,
			input: () => `${prefix}-input`,
			placeholder: () => `${prefix}-placeholder`,
			indicatorSeparator: () => `${prefix}-indicatorSeparator`,
			noOptionsMessage: () => `${prefix}-empty`,
			option: () => `${prefix}-option`,
			singleValue: () => `${prefix}-value`,
		}}
		className={`${prefix} ${props.className || ''}`}
	/>;
};

export default BaseSelect;
