import { setLocale } from 'yup';

setLocale({
	mixed: {
		required: 'Required field!',
	},
	string: {
		email: 'Invalid email',
	},
});
