import { Action, ActionCreatorsMapObject, Reducer } from 'redux';

import { BaseParams } from '@common/typescript/objects/BaseParams';
import { BaseUser } from '@common/typescript/objects/BaseUser';
import { BaseApplicationState, BaseAppThunkAction } from '@common/react/store/index';
import { request } from '@common/react/components/Api';
import { List } from '@common/typescript/objects/List';
import { WithId } from '@common/typescript/objects/WithId';
import { equal } from '@common/typescript/Utils';

export interface ItemsState<T> {
	isLoading: boolean;
	items: Array<T>;
	type: string;
	pagination: {
		total: number;
		current: number;
		offset: number;
		pageSize?: number;
	};
	params: BaseParams;
	isEmpty?: boolean;
}

export enum TypeKeys {
	SETITEMS = 'SETITEMS',
	UPDATEITEM = 'UPDATEITEM',
	RECEIVEITEMS = 'RECEIVEITEMS',
	REQUESTITEMS = 'REQUESTITEMS',
	REMOVEITEM = 'REMOVEITEM',
	ADDITEM = 'ADDITEM',
}

interface RequestItemsAction {
	type: TypeKeys.REQUESTITEMS;
	storageName: string | null;
	params: any;
	objectType: string;
}

interface ReceiveItemsAction<T> {
	type: TypeKeys.RECEIVEITEMS;
	storageName: string | null;
	items: Array<T>;
	total: number;
	offset: number;
	objectType: string;
	isEmpty?: boolean;
}

export interface SetItemsAction<T> {
	type: TypeKeys.SETITEMS;
	storageName: string | null;
	items: Array<T> | null | undefined;
	total?: number | null;
	params: any;
	objectType: string;
	current?: number;
	isEmpty?: boolean;
}

interface UpdateItemAction<T> {
	type: TypeKeys.UPDATEITEM;
	storageName: string | null;
	paramName: keyof T;
	value: Partial<T>;
}

interface RemoveItemAction<T> {
	type: TypeKeys.REMOVEITEM;
	storageName: string | null;
	paramName: keyof T;
	value: any;
}

interface AddItemAction<T> {
	type: TypeKeys.ADDITEM;
	sort?: (items: Array<T>) => Array<T>;
	storageName: string | null;
	value: any;
}

export type KnownPageAction<T> = SetItemsAction<T>
	| UpdateItemAction<T>
	| RequestItemsAction
	| ReceiveItemsAction<T>
	| RemoveItemAction<T>
	| AddItemAction<T>;

const loadPage = <T, TUser extends BaseUser, TApplicationState extends BaseApplicationState<TUser>>(
	dispatch: any,
	getState: any,
	store: string,
	type: string,
	path: string,
	params: BaseParams,
) => {
	const fetchTask = request<List<T>, TUser, TApplicationState>(path, params, getState()).then((data) => {
		dispatch({
			type: TypeKeys.RECEIVEITEMS,
			storageName: store,
			items: data.list,
			total: data.count,
			objectType: type,
			params,
			offset: data.offset,
		});

		return data.list;
	}).catch(() => {
		dispatch({
			type: TypeKeys.RECEIVEITEMS,
			storageName: store,
			items: [],
			total: 0,
			objectType: type,
			params,
			offset: 0,
			isEmpty: true,
		});

		return [];
	});

	dispatch({
		type: TypeKeys.REQUESTITEMS, storageName: store, params, objectType: type,
	});

	return fetchTask;
};

export const getActionCreators = <
	T extends WithId,
	TUser extends BaseUser,
	TApplicationState extends BaseApplicationState<TUser>
	>(): ActionCreatorsMapObject => {
	return {
		reqPages: (
			store: string,
			path: string,
			type: string,
			params: BaseParams,
		): BaseAppThunkAction<KnownPageAction<T>, TUser, TApplicationState> => (dispatch, getState) => {
			const storeState = (getState() as any)[store];

			if (!equal(storeState.params, params) || storeState.type !== type) {
				return loadPage<T, TUser, TApplicationState>(dispatch, getState, store, type, path, params);
			}

			return Promise.resolve(storeState.items);
		},
	};
};

export const getReducer = <T>(storageName: string):Reducer<ItemsState<T>> => {
	return (s: ItemsState<T> | undefined, incomingAction: Action) => {
		const state = s as ItemsState<T>;
		const action = incomingAction as KnownPageAction<T>;
		if (!action.storageName || action.storageName === storageName) {
			switch (action.type) {
				case TypeKeys.SETITEMS:
					return {
						isLoading: false,
						items: action.items || [],
						params: action.params || {},
						pagination: {
							total: action.total || (action.items && action.items.length) || 0,
							current: action.current || 0,
							offset: 0,
							pageSize: action.params.count || 10,
						},
						type: action.objectType,
						isEmpty: action.isEmpty,
					};
				case TypeKeys.UPDATEITEM:
					return {
						...state,
						items: state.items.map((item) => {
							return item[action.paramName] === action.value[action.paramName] ? { ...item, ...action.value } : item;
						}),
					};
				case TypeKeys.ADDITEM:
					const newArr = state.items.concat(action.value);
					return {
						...state,
						items: action.sort ? action.sort(newArr) : newArr,
						pagination: {
							...state.pagination,
							total: state.pagination.total !== undefined ? state.pagination.total + 1 : 1,
						},
					};
				case TypeKeys.REQUESTITEMS:
					return {
						...state, isLoading: true, params: action.params, type: action.objectType,
					};
				case TypeKeys.RECEIVEITEMS:
					return {
						isLoading: false,
						items: action.items,
						params: state.params,
						pagination: {
							total: action.total, current: state.params.page, offset: action.offset, pageSize: state.params.count || 10,
						},
						type: action.objectType,
						isEmpty: action.isEmpty,
					};
				case TypeKeys.REMOVEITEM:
					return {
						...state,
						items: state.items.filter((item) => item[action.paramName] !== action.value),
						pagination: {
							...state.pagination,
							total: state.pagination.total !== undefined ? state.pagination.total - 1 : 0,
						},
					};
				default:
					return state || {
						isLoading: false,
						params: {},
						items: [],
						pagination: {
							total: 0,
							current: 1,
							offset: 0,
						},
						isEmpty: true,
					};
			}
		}

		return state;
	};
};
